import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { MotionPlugin } from "@vueuse/motion";
import VueCompositionAPI from "@vue/composition-api";

Vue.config.productionTip = false;

Vue.use(Toast);
Vue.use(MotionPlugin);
Vue.use(VueCompositionAPI);

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
