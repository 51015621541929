<template>
  <v-card class="text-center ma-5 pa-2" elevation="4" v-motion-slide-right>
    <h2 class="mt-2">Maak nu een afspraak!</h2>
    <form ref="form" @submit.prevent="sendEmail">
      <label>Naam</label>
      <input type="text" name="name" required />
      <label>Email</label>
      <input type="email" name="email" required />
      <label>Bedrijf</label>
      <input type="text" name="company" required />
      <label>Bericht</label>
      <textarea name="message" required></textarea>
      <input type="submit" value="Stuur" />
    </form>
  </v-card>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "Afspraak",
  data: () => ({
    name: "",
    email: "",
    company: "",
    message: "",
  }),
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_m9ahk28",
          "template_g3jhman",
          this.$refs.form,
          "user_WxjttNtdAfc6GGiefSjvQ"
        )
        .then((result) => {
          console.log("SUCCESS!", result.text);
          this.$toast.success(
            "Het bericht is verstuurd! Je hoort zo spoedig mogelijk van mij.",
            {
              timeout: 3000,
            }
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((error) => {
          console.log("FAILED...", error.text);
          this.$toast.error("Er is iets fout gegaan. Probeer het opniew.", {
            timeout: 3000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        });
    },
  },
};
</script>

<style scoped>
label {
  float: left;
}
input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="text"]:focus {
  outline: 1px solid #d99318;
}

[type="email"]:focus {
  outline: 1px solid #d99318;
}

textarea:focus {
  outline: 1px solid #d99318;
}

input[type="submit"] {
  background-color: #d99318;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #442e08;
}
</style>
