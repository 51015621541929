<template>
  <v-app>
    <v-main>
      <v-layout class="mt-16"></v-layout>
      <v-layout row wrap justify-space-between class="ma-10 mt-16">
        <v-flex sm12 md6>
          <v-card
            class="text-center ma-5 pa-2"
            elevation="4"
            min-height="522px"
            v-motion-slide-left
          >
            <v-img
              class="imgA mx-auto ma-8"
              src="/antonvanderaar.jpg"
              max-height="300px"
              max-width="300px"
            ></v-img>
            <h1 class="display-3 my-10">Anton van der Aar</h1>
          </v-card>
        </v-flex>
        <v-flex sm12 md4>
          <Afspraak />
        </v-flex>
      </v-layout>
      <Information />
    </v-main>
  </v-app>
</template>

<script>
import Information from "./components/Information.vue";
import Afspraak from "./components/Afpsraak.vue";

export default {
  components: { Information, Afspraak },
  name: "App",
  data: () => ({}),
  methods: {},
};
</script>

<style>
#app {
  background: url("/background-avdaar-fm.png");
  background-size: cover;
}

.imgA {
  border-radius: 50%;
}

.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}
</style>
